h2 {
  display: inline-block;
  font-size: 24px;
}
.h2-plus-icon {
  margin-left: 50px;
}

.form-container {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, .5); */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* order: 2; */
  flex-direction: column;
}

label {
  display: table;
  z-index: 1000;
  margin: 0px auto;
  font-size: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: -15px;
  margin-left: 20px;
  color: #555555;
}

.input-wrapper {
  position: relative;
}

input[type="text"],
input[type="email"],
input[type="date"],
input[type="time"],
select,
textarea {
  border: 1px solid gray;
  position: relative;
  line-height: 4ex;
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333333;
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Gap between items */
}

/* Two items per row, accounting for gap */
.label-input-group {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 15px);
}

/* text area for visit reason */
.label-textarea-group {
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 15px);
}
/* text area for visit reason */
.h2-blocklevel {
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 15px);
}

/* Three items per row, accounting for gap */
.label-input-group.three-col {
  flex-basis: calc(33.333% - 15px);
}

/* -------------------------toggle--------https://codepen.io/mallendeo/pen/QWKrEL------------- */

/* Hide the default checkbox */
.toggle input {
  display: none;
}

/* Style the slider */
.slider {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 46px;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.3s;
}

/* Style the circle inside the slider */
/* Circle inside the slider */
.slider:before {
  position: absolute;
  content: "No";
  font-size: 15px;
  height: 38px;
  width: 38px;
  left: 4px;
  bottom: 4px;
  background-color: rgb(203,108,181);
  color: whitesmoke;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  transition: transform 0.3s, content 0.3s;
}

/* Change background color when checked */
.toggle input:checked + .slider {
  background-color: rgba(244, 16, 191, 0.575);
}

.position-toggle {
  margin-left: 20px;
  margin-bottom: -10px;
}

/* Move the circle and change text when checked */
.toggle input:checked + .slider:before {
  transform: translateX(30px);
  background-color: rgb(167, 2, 128);
  content: "Yes";
}
/* -------------------------toggle-end-------------------- */

textarea {
  width: 100%;
}

.next-button {
  width: 100%;
  height: 75px;
  font-size: 24px;
  color: whitesmoke;
  background-color: rgb(118, 27, 97);
  border-radius: 5px;
  border: none;
}

.color-alert {
  font-weight: bold;
  color: rgb(203,108,181);
  margin-top: 5px;
}

.signature-div {
  height: 150px;
  width: 100%;
  background-color: #cccccc74;
  border-radius: 5px;
  border: 3px dashed rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
}
.signature-div p {
  color: rgba(128, 128, 128, 0.61);
  text-align: center;
  margin-top: 60px;
}

 /* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
 input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }